import $ from "jquery";
import "bootstrap";
import "../css/main.scss" // Import our scss file

let ver = 'e';
let lang = 'en';
$(".spec-download-link").attr("href", `./spec/PeSIT-${ver}-${lang}.pdf`);

// set bootstrap dropdown toggle to the selected value
// and change the spec download link accordingly
$('.dropdown-item').click(function() {
    ver = $(this).attr('ver');
    lang = $(this).attr('lang');

    $('.droppy .chosen').text($(this).text());

    $(".spec-download-link").attr("href", `./spec/PeSIT-${ver}-${lang}.pdf`);
});

$('.spec-view').click(function(e) {
    window.open(`./spec/PeSIT-${ver}-${lang}.pdf`, '_blank');
})